import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { withTrans } from '../i18n/withTrans';
import { Grid, Snackbar } from '@material-ui/core';
import ContactGroupForm from '../components/ReservationGroup/ContactGroupForm';
import Layout from '../components/Layout/Layout';
import { Helmet } from 'react-helmet';
import Favicon from '../assets/images/favicon-manatour-16x16.png';
import calendarImg from '../assets/images/calendar_bg.jpg';
import GroupInfoForm from '../components/ReservationGroup/GroupInfoForm';
import useAuthContext from '../contexts/AuthContext';
import { navigate } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ModalConfirmation from '../components/ReservationGroup/ModalConfirmation';
import { Alert } from '@material-ui/lab';

function reducer(state, action) {
  return { ...state, ...action };
}

const requiredContactFields = ['prenom', 'nom', 'email', 'email_validation'];

const requiredGroupFields = [
  'address',
  'desired_dates',
  'desired_hour',
  'desired_language',
  'email',
  'estimated_visitors_number',
  'name',
  'organization_type',
  'phone',
  'visit_theme',
  'visitors_profile'
];

const validationEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email?.toLowerCase());
};

const validatePhone = (phone) => {
  let check = false;
  const phoneTest =
    /^(?:(?:\+|00)?([0-9]{0,3})|0)\s*[1-9](?:[\s.-]*\d{2}){4,5}$/;
  if (phone.match(phoneTest)) {
    check = true;
  }
  return check;
};

const checkGroupFormConformityFields = (globalState) => {
  let isFormValid = true;
  const { contact, group } = globalState;

  const errorContactFields = requiredContactFields.reduce((acc, field) => {
    if (field === 'email') {
      if (!validationEmail(contact?.[field])) acc.push('email_format');
    }
    if (!contact?.[field]) {
      acc.push(field);
    }
    return acc;
  }, []);

  const errorGroupFields = requiredGroupFields.reduce((acc, field) => {
    if (field === 'email') {
      if (!validationEmail(group?.[field])) acc.push('email_format');
    }
    if (!group?.[field]) {
      acc.push(field);
    }
    return acc;
  }, []);

  if (errorContactFields?.length) {
    errorContactFields.push('contact');
    isFormValid = false;
  }

  if (errorGroupFields?.length) {
    isFormValid = false;
  }
  return { isFormValid, errorContactFields, errorGroupFields };
};

const ReservationGroup = ({ t }) => {
  const { dispatchAPI } = useAuthContext();
  const [state, dispatch] = useReducer(reducer, {
    contact: {},
    group: {},
    visible: false,
    newsletter: false
  });

  const onCancel = () => {
    navigate('/reservation/');
  };

  const handleClose = () => {
    dispatch({ openValErrorsSnack: false });
  };

  const sendEmail = async () => {
    try {
      await dispatchAPI('POST', {
        url: `/orders/send_email_group`,
        body: state
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      if (e.response) console.error(e.response.status);
    }
  };

  const validateForm = async () => {
    const { isFormValid, errorContactFields, errorGroupFields } =
      checkGroupFormConformityFields(state);
    dispatch({
      errors_validation: errorContactFields,
      openValErrorsSnack:
        errorContactFields?.length || errorGroupFields.length ? true : false,
      errors_validation_group: errorGroupFields
    });
    if (isFormValid) {
      await sendEmail();
      dispatch({ visible: true });
    }
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LfU8eAcAAAAAE53fE-ZeWrGKel7iX2Xls-4AOfN">
      <Layout pageName="reservation_group_page">
        <Helmet>
          <title>{t('helmet.reservation_page')}</title>
          <link
            rel="icon"
            type="image/png"
            href={Favicon}
            sizes="16x16"
            className="favicon"
          />
        </Helmet>
        <h2>{t('reservationGroupPage.page-title')}</h2>
        <div className="group-form-wrapper">
          {(state.errors_validation || []).length !== 0 && (
            <Snackbar
              open={state.openValErrorsSnack}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert severity="error">{t('errors.fieldsIncomplete')}</Alert>
            </Snackbar>
          )}
          {state.visible && (
            <ModalConfirmation
              t={t}
              visible={state.visible}
              dispatch={dispatch}
            />
          )}
          <Grid
            container
            style={{
              backgroundImage: `url(${calendarImg})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}
          >
            <Grid item xs={6}>
              <p>
                {t('reservationGroupPage.title')}
                <span>{t('reservationGroupPage.contact')}</span>
              </p>
              <ContactGroupForm
                state={state}
                dispatch={dispatch}
                t={t}
                validationEmail={validationEmail}
                validatePhone={validatePhone}
              />
            </Grid>
            <Grid item xs={6}>
              <p>
                {t('reservationGroupPage.title')}
                <span>{t('reservationGroupPage.group')}</span>
              </p>
              <GroupInfoForm
                state={state}
                dispatch={dispatch}
                t={t}
                validationEmail={validationEmail}
                validatePhone={validatePhone}
              />
            </Grid>
          </Grid>
          <div style={{ float: 'right', margin: '12px 0 8px 0' }}>
            <button
              onClick={() => onCancel()}
              className="btn-secondary"
              style={{ marginRight: 10 }}
            >
              {t('buttons.back')}
            </button>
            <button className="btn-secondary" onClick={() => validateForm()}>
              {t('buttons.send')}
            </button>
          </div>
        </div>
      </Layout>
    </GoogleReCaptchaProvider>
  );
};

export default withTrans(ReservationGroup);

ReservationGroup.propTypes = {
  t: PropTypes.func.isRequired
};
